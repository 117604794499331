jQuery(document).ready(function ($) {

    $('.search-autocomplete').autoComplete({
        minChars: 2,
        source: function(term, suggest) {
            term = term.toLowerCase();
            var choices = [];
            $.post(global.ajax, { search: term, action: 'search_site' }, function(res) {
                
                $.each( res.data, function( key, value ) {
                    choices.push([value[0], value[1], value[2], value[3]]);
                    // console.log([value[0]]);
                });
                //console.log(res.data);

                //var choices_c = [['Australia', 'au'], ['Austria', 'at'], ['Brasil', 'br']];
                //console.log(choices);
                setTimeout(function(){
                    // console.log(choices.length);
                    // console.log(res.data);
                    var suggestions = [];
                    for (i=0;i<choices.length;i++)
                        suggestions.push(choices[i]);
                    suggest(suggestions);

                }, 100);

            });
        },
        renderItem: function (item, search){
            search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            // console.log(item);
            var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
            // return '<div class="autocomplete-suggestion" data-value="'+item[0]+'" data-url="'+item[1]+'" data-val="'+search+'">'+item[0]+'</div>';
            return '<li><a href="'+item[1]+'"><div class="img" style="background-image: url('+item[3]+')"></div><div class="blog-search-results-content"><span class="blog-search-results-title">'+item[0]+'</span><p>'+item[2]+'</p></div></a></li>';
            
        },
        onSelect: function(e, term, item){
            // window.location.href = item.data('url');
            //alert('Item "'+item.data('value')+' ('+item.data('url')+')" selected by '+(e.type == 'keydown' ? 'pressing enter' : 'mouse click')+'.');
        }
    });


    // var leftH = jQuery('#hamburger-icon .hamburger-box').offset().left;
    // var hWidth = jQuery('#hamburger-icon .hamburger-box').width();
    // var windowWidth = jQuery(window).width();
    // var rightBy = windowWidth - leftH - hWidth;

    // Columns with same height
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    // CONVERT URLS TO HREF IN SUBPAGES
    if($( '#subpages-data' ).length != 0) {
        var txt = $( '#subpages-data' ).html();
        var pattern = /<p>[\s\n]*(https?:\/\/nationwideautotransportation.com\/([\w-]+))[\/\s\n]*<\/p>/g;
    
        txt = txt.replace( pattern, function( match, url, name ) {
            name = name.replace( /-/g, ' ' );
    
            return '<p><a style="text-transform: capitalize;" href="' + url + '">' + name + '</a></p>';
        });
    
        $( '#subpages-data' ).html( txt );
    }

    // TODO: just for testing, remove later.
    // // Blog search
    // const blogSearchForm = document.querySelector(".blog-search-wrapper form");

    // if (blogSearchForm) {
    //     const blogSearchFormInput = blogSearchForm.querySelector(
    //         ".blog-search-input"
    //     );

    //     blogSearchFormInput.addEventListener("input", function() {
    //         if (blogSearchFormInput.value.length > 0) {
    //         blogSearchForm.classList.add("active");
    //         } else {
    //         blogSearchForm.classList.remove("active");
    //         }
    //     });
    // }

    // Menu Mobile
    const menuToggle = document.querySelectorAll(".menu-toggle");
    const menuMobile = document.querySelector(".header-navbar-menu");

    if (menuToggle.length > 0) {
         for (var i = 0; i < menuToggle.length; i++) {
            var element = menuToggle[i];

            element.addEventListener("click", function() {
            menuMobile.classList.contains("is-visible")
                ? menuMobile.classList.remove("is-visible")
                : menuMobile.classList.add("is-visible");
            });
        }
    }
});
